import { ICellEditorComp, ICellEditorParams } from "ag-grid-community";

interface LocaleStrings {
  placeholder: string;
}

interface DropdownEditorParams extends ICellEditorParams {
  returnCodes: Record<string, string>;
  selectorPlaceholderLocales: LocaleStrings;
}

export class DropdownEditor implements ICellEditorComp {
  private static readonly CSS_CLASSES = {
    CONTAINER: "ag-cell-editor w-full h-full relative",
    INPUT: "w-full h-full border-none text-sm focus:outline-none bg-transparent cursor-default pl-3 truncate",
    POPUP: "fixed bg-white border border-gray-300 rounded shadow-lg p-2 min-w-[240px] z-[9999]",
    SEARCH_INPUT: "w-full px-2 py-1.5 border border-gray-300 rounded text-sm mb-2 search-input-container",
    OPTIONS_LIST: "max-h-24 overflow-y-auto options-container",
    OPTION: "px-2 py-1.5 hover:bg-gray-100 cursor-pointer text-sm option-container",
    OPTION_SELECTED: "bg-blue-50",
  } as const;

  private readonly container: HTMLDivElement;
  private readonly input: HTMLInputElement;
  private readonly popup: HTMLDivElement;
  private readonly searchInput: HTMLInputElement;
  private readonly optionsList: HTMLDivElement;
  private params!: DropdownEditorParams;
  private value: string = "";
  private originalValue: string = "";
  private columnName: string = "";
  private filteredOptions: Array<[string, string]> = [];
  private boundHidePopup: (e: MouseEvent) => void;

  constructor() {
    const elements = this.createDOMElements();
    this.container = elements.container;
    this.input = elements.input;
    this.popup = elements.popup;
    this.searchInput = elements.searchInput;
    this.optionsList = elements.optionsList;

    this.boundHidePopup = this.handleOutsideClick.bind(this);
    this.setupEventListeners();
  }

  private createDOMElements() {
    const container = document.createElement("div");
    container.className = DropdownEditor.CSS_CLASSES.CONTAINER;

    const input = document.createElement("input");
    input.className = DropdownEditor.CSS_CLASSES.INPUT;
    input.readOnly = true;
    container.appendChild(input);

    const popup = document.createElement("div");
    popup.className = DropdownEditor.CSS_CLASSES.POPUP;
    popup.style.display = "none";

    const searchInput = document.createElement("input");
    searchInput.className = DropdownEditor.CSS_CLASSES.SEARCH_INPUT;

    const optionsList = document.createElement("div");
    optionsList.className = DropdownEditor.CSS_CLASSES.OPTIONS_LIST;

    popup.appendChild(searchInput);
    popup.appendChild(optionsList);
    document.body.appendChild(popup);

    return { container, input, popup, searchInput, optionsList };
  }

  private setupEventListeners(): void {
    this.optionsList.addEventListener("click", (e) => {
      const target = e.target as HTMLElement;
      if (target.dataset.code !== undefined) {
        this.selectOption(target.dataset.code);
      }
    });

    this.input.addEventListener("click", this.showPopup.bind(this));
    this.searchInput.addEventListener("input", this.filterAndRenderOptions.bind(this));
    this.searchInput.addEventListener("keydown", (e) => {
      if (e.key === "Escape") this.hidePopup();
    });
  }

  init(params: DropdownEditorParams): void {
    this.params = params;
    this.value = params.value;
    this.columnName = params.column.getColId();
    this.filteredOptions = Object.entries(params.returnCodes);
    this.originalValue = params.value;
    this.searchInput.placeholder = params.selectorPlaceholderLocales.placeholder;
    this.updateInputValue();
  }

  getColumnName(): string {
    return this.columnName;
  }

  getOriginalValue(): string {
    return this.originalValue;
  }

  private showPopup(e: MouseEvent): void {
    e.stopPropagation();
    const rect = this.container.getBoundingClientRect();

    Object.assign(this.popup.style, {
      top: `${rect.top + window.scrollY}px`,
      left: `${rect.left + window.scrollX}px`,
      width: `${rect.width}px`,
      display: "block",
    });

    this.searchInput.value = "";
    this.filterAndRenderOptions();

    requestAnimationFrame(() => {
      document.addEventListener("click", this.boundHidePopup);
      this.searchInput.focus();
    });
  }

  private hidePopup(): void {
    this.popup.style.display = "none";
    document.removeEventListener("click", this.boundHidePopup);
  }

  private handleOutsideClick(e: MouseEvent): void {
    if (!this.popup.contains(e.target as Node) && !this.container.contains(e.target as Node)) {
      this.hidePopup();
    }
  }

  private filterAndRenderOptions(): void {
    const searchText = this.searchInput.value.toLowerCase();
    this.filteredOptions = searchText
      ? Object.entries(this.params.returnCodes).filter(
          ([code, label]) => code.toLowerCase().includes(searchText) || label.toLowerCase().includes(searchText),
        )
      : Object.entries(this.params.returnCodes);

    this.renderOptions();
  }

  private renderOptions(): void {
    const fragment = document.createDocumentFragment();

    const emptyOption = document.createElement("div");
    emptyOption.className = `${DropdownEditor.CSS_CLASSES.OPTION}${
      this.value === "" ? ` ${DropdownEditor.CSS_CLASSES.OPTION_SELECTED}` : ""
    }`;

    emptyOption.style.minHeight = "24px";
    emptyOption.dataset.code = "";
    fragment.appendChild(emptyOption);

    this.filteredOptions.forEach(([code, label]) => {
      const option = document.createElement("div");
      option.className = `${DropdownEditor.CSS_CLASSES.OPTION}${
        code === this.value ? ` ${DropdownEditor.CSS_CLASSES.OPTION_SELECTED}` : ""
      }`;
      option.textContent = label;
      option.dataset.code = code;
      fragment.appendChild(option);
    });

    this.optionsList.textContent = "";
    this.optionsList.appendChild(fragment);
  }

  private selectOption(code: string): void {
    this.value = code;
    this.updateInputValue();
    this.hidePopup();
    this.params.node.setDataValue(this.params.column.getColId(), code);
  }

  private updateInputValue(): void {
    this.input.value = this.value ? this.params.returnCodes[this.value] || "" : "";
  }

  getGui(): HTMLElement {
    return this.container;
  }

  getValue(): string {
    return this.value;
  }

  afterGuiAttached(): void {
    this.input.focus();
  }

  destroy(): void {
    document.removeEventListener("click", this.boundHidePopup);
    document.body.removeChild(this.popup);
  }

  isPopup(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }
}

import { IFilterComp, IFilterParams, IDoesFilterPassParams } from "ag-grid-community";

interface LocaleStrings {
  placeholder: string;
  apply_button: string;
  reset_button: string;
}

interface DropdownFilterParams extends IFilterParams {
  returnCodes: Record<string, string>;
  selectorPlaceholderLocales: LocaleStrings;
}

interface FilterModel {
  values: string[];
  filterType: "text";
  type: "set";
}

interface FilterElements {
  container: HTMLElement;
  select: HTMLSelectElement;
  input: HTMLInputElement;
  applyButton: HTMLButtonElement;
  resetButton: HTMLButtonElement;
}

interface FilterState {
  filterText: string;
  filteredOptions: Map<string, string>;
  options: Map<string, string>;
  selectedValues: Set<string>;
  locales?: LocaleStrings;
}

export class DropdownFilter implements IFilterComp {
  private readonly elements: FilterElements;
  private readonly state: FilterState;
  private params!: DropdownFilterParams;
  private hidePopup?: () => void;

  private static readonly CSS_CLASSES = {
    CONTAINER: "bg-white border border-gray-300 rounded p-2 min-w-[240px]",
    SELECT: "w-60 mt-1 border border-gray-300 rounded text-sm p-1 box-border",
    INPUT: "w-full px-2 py-1.5 border border-gray-300 rounded text-sm box-border",
    BUTTON: "px-3 py-1.5 border border-gray-300 rounded bg-white hover:bg-gray-50 cursor-pointer text-sm",
    FILTER_WRAPPER: "flex flex-col",
    OPTIONS_CONTAINER: "relative w-full",
    BUTTONS_CONTAINER: "flex justify-end gap-2 pt-2",
  };

  constructor() {
    this.elements = this.createElements();
    this.state = {
      filterText: "",
      filteredOptions: new Map(),
      options: new Map(),
      selectedValues: new Set(),
    };
    this.setupEventListeners();
  }

  private createElements(): FilterElements {
    const container = document.createElement("div");
    const select = document.createElement("select");
    const input = document.createElement("input");
    const resetButton = document.createElement("button");
    const applyButton = document.createElement("button");

    container.className = DropdownFilter.CSS_CLASSES.CONTAINER;
    select.className = DropdownFilter.CSS_CLASSES.SELECT;
    input.className = DropdownFilter.CSS_CLASSES.INPUT;
    resetButton.className = DropdownFilter.CSS_CLASSES.BUTTON;
    applyButton.className = DropdownFilter.CSS_CLASSES.BUTTON;
    select.size = 3;

    this.assembleElements(container, input, select, resetButton, applyButton);
    return { container, select, input, resetButton, applyButton };
  }

  private assembleElements(
    container: HTMLElement,
    input: HTMLInputElement,
    select: HTMLSelectElement,
    resetButton: HTMLButtonElement,
    applyButton: HTMLButtonElement,
  ): void {
    const fragment = document.createDocumentFragment();
    const filterWrapper = document.createElement("div");
    const optionsContainer = document.createElement("div");
    const buttonsContainer = document.createElement("div");

    filterWrapper.className = DropdownFilter.CSS_CLASSES.FILTER_WRAPPER;
    optionsContainer.className = DropdownFilter.CSS_CLASSES.OPTIONS_CONTAINER;
    buttonsContainer.className = DropdownFilter.CSS_CLASSES.BUTTONS_CONTAINER;

    optionsContainer.appendChild(input);
    buttonsContainer.append(resetButton, applyButton);
    filterWrapper.append(optionsContainer, select);
    fragment.append(filterWrapper, buttonsContainer);
    container.appendChild(fragment);
  }

  private setupEventListeners(): void {
    const { input, select, resetButton, applyButton } = this.elements;

    input.addEventListener("input", this.onFilterTextChanged.bind(this));
    select.addEventListener("change", this.onSelectionChanged.bind(this));
    resetButton.addEventListener("click", this.onReset.bind(this));
    applyButton.addEventListener("click", this.onApply.bind(this));
  }

  init(params: DropdownFilterParams): void {
    this.params = params;
    this.state.locales = params.selectorPlaceholderLocales;

    const { input, applyButton, resetButton } = this.elements;
    input.placeholder = this.state.locales.placeholder;
    applyButton.textContent = this.state.locales.apply_button;
    resetButton.textContent = this.state.locales.reset_button;

    this.setupHidePopup();
    this.initializeOptions(params);
    this.populateOptions();
  }

  private setupHidePopup(): void {
    this.hidePopup = () => {
      requestAnimationFrame(() => {
        const filterMenu = this.elements.container.closest(".ag-menu.ag-filter-menu");
        filterMenu?.remove();
      });
    };
  }

  private initializeOptions(params: DropdownFilterParams): void {
    this.state.options = new Map(Object.entries(params.returnCodes));
    this.state.filteredOptions = new Map(this.state.options);

    const initialModel = this.getModel();
    if (initialModel?.values) {
      initialModel.values.forEach((val) => this.state.selectedValues.add(val));
    }
  }

  private updateFilteredOptions(): void {
    const { options, filterText } = this.state;
    this.state.filteredOptions.clear();

    for (const [key, label] of options) {
      if (this.matchesFilter(key, label, filterText)) {
        this.state.filteredOptions.set(key, label);
      }
    }
  }

  private matchesFilter(key: string, label: string, filterText: string): boolean {
    const searchText = filterText.toLowerCase();
    return label.toLowerCase().includes(searchText) || key.toLowerCase().includes(searchText);
  }

  private createOption(key: string, label: string): HTMLOptionElement {
    const option = document.createElement("option");
    option.value = key;
    option.text = label;
    option.selected = this.state.selectedValues.has(key);
    return option;
  }

  getGui(): HTMLElement {
    return this.elements.container;
  }

  onFilterTextChanged(): void {
    this.state.filterText = this.elements.input.value.toLowerCase();
    this.populateOptions();
  }

  populateOptions(): void {
    this.updateFilteredOptions();

    const fragment = document.createDocumentFragment();
    for (const [key, label] of this.state.filteredOptions) {
      fragment.appendChild(this.createOption(key, label));
    }

    this.elements.select.innerHTML = "";
    this.elements.select.appendChild(fragment);
  }

  onSelectionChanged(): void {
    this.state.selectedValues.clear();
    Array.from(this.elements.select.selectedOptions).forEach((option) => this.state.selectedValues.add(option.value));
  }

  onApply(): void {
    this.params.filterChangedCallback();
    this.hidePopup?.();
  }

  onReset(): void {
    this.elements.input.value = "";
    this.state.filterText = "";
    this.state.selectedValues.clear();
    this.populateOptions();
    this.params.filterChangedCallback();
    this.hidePopup?.();
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return !this.isFilterActive() || this.state.selectedValues.has(params.data[this.params.colDef.field!]);
  }

  isFilterActive(): boolean {
    return this.state.selectedValues.size > 0;
  }

  getModel(): FilterModel | null {
    return this.isFilterActive()
      ? {
          values: Array.from(this.state.selectedValues),
          filterType: "text",
          type: "set",
        }
      : null;
  }

  setModel(model: FilterModel | null): void {
    this.state.selectedValues.clear();

    if (model?.values) {
      model.values.forEach((value) => this.state.selectedValues.add(value));
    }

    Array.from(this.elements.select.selectedOptions).forEach(
      (option) => (option.selected = this.state.selectedValues.has(option.value)),
    );
  }

  afterGuiAttached(): void {
    this.elements.input.focus();
  }
}

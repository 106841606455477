import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as columns from "../../grid/columns";
import * as datasource from "../../grid/datasource";
import * as dom from "../../utils/dom";
import * as gridUtils from "../../grid/utils";
import * as http from "../../utils/http";
import * as notifications from "utils/notifications";
import * as rowActions from "./row_actions";
import * as translations from "utils/translations";
import { ColumnsState } from "grid/columns_state";
import { DropdownFilter } from "grid/dropdown_filter";
import { DropdownEditor } from "grid/dropdown_editor";
const httpClient = http.buildClient(rowActions.cutoffTimeResponseInterceptor);

import {
  ACTIONS_COL_DEF,
  AMOUNT_COL_DEF,
  AMOUNT_FILTER_COL_DEF,
  DATE_FILTER_COL_DEF,
  DATE_FILTER_UNTIL_NOW_COL_DEF,
  DEFAULT_COL_DEF,
  FILTER_TRANSLATIONS,
  TEXT_FILTER_COL_DEF,
} from "../../grid/columns";

async function handleDiscard(params: rowActions.ActionEvent<MadePayment>) {
  const response = await httpClient.httpDelete(params.data.discard_path);

  if (response.ok) {
    if (params.data.status == MadePaymentStatus.PROVISIONAL_RETURN) {
      const data = await response.json();
      params.api.applyTransaction({ update: [data] });
    } else {
      params.api.applyTransaction({ remove: [params.data] });
    }
  } else {
    const data = await response.json();
    notifications.alert(data.error);
  }
}

function handleEdit(params: rowActions.ActionEvent<MadePayment>, defaultPaymentMethod: string) {
  params.data.payment_amount ||= params.data.due_amount;
  params.data.payment_date = columns.formatDateToUSFormat(params.data.payment_date);
  params.data.payment_method ||= defaultPaymentMethod;
  params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: "payment_method" });
  rowActions.show(gridUtils.actionsCell(params), true);
}

async function handleProvision(params: rowActions.ActionEvent<MadePayment>) {
  var response: Response;
  params.api.stopEditing();

  const body = {
    made_payment: {
      return_code: params.data.return_code === "" ? "blank" : params.data.return_code,
      status: params.data.status,
    },
  };
  response = await httpClient.httpPatch(params.data.update_path, body);
  const data = await response.json();

  if (response.ok) {
    params.api.applyTransaction({ update: [data] });
  } else {
    notifications.alert(data.error);
  }
}

const enum MadePaymentStatus {
  PROVISIONAL_RETURN = "PROVISIONAL_RETURN",
}

interface MadePayment {
  comment: string;
  currency_code: string;
  customer_name: string;
  discard_path: string;
  due_amount: number;
  due_date: string;
  editable: boolean;
  id: string;
  payment_amount: number;
  payment_date: string;
  payment_method: string;
  reference_number: string;
  return_code: string;
  status: MadePaymentStatus;
  update_path: string;
}

interface CtrlCallbacks {
  subscribeOnResetColumnWidths(callback: () => void): void;
  subscribeOnRowDataUpdated(event: AgGrid.RowDataUpdatedEvent<MadePayment, any>): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const {
    defaultPaymentMethod,
    i18nJson,
    indexPath,
    paginationPageSize,
    paymentMethodsJson,
    returnCodesJson,
    selectorPlaceholderLocales,
  } = $grid.dataset;

  const returnCodes = JSON.parse(returnCodesJson);
  const colDefs: AgGrid.ColDef[] = [
    { field: "account_number", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "due_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    { field: "due_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true, sort: "desc" },
    { field: "customer_name", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "payment_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "payment_method",
      ...DEFAULT_COL_DEF,
      ...columns.buildEnumColDef(JSON.parse(paymentMethodsJson)),
      ...columns.buildEnumFilterColDef(JSON.parse(paymentMethodsJson)),
      sortable: true,
    },
    { field: "payment_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_UNTIL_NOW_COL_DEF, sortable: true },
    { field: "reference_number", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "comment", ...DEFAULT_COL_DEF, tooltipField: "comment", sortable: false },
    {
      field: "return_code",
      ...DEFAULT_COL_DEF,
      editable: true,
      cellEditor: DropdownEditor,
      cellEditorParams: {
        returnCodes: returnCodes,
        selectorPlaceholderLocales: JSON.parse(selectorPlaceholderLocales),
      },
      cellRenderer(params) {
        return returnCodes[params.value];
      },
      tooltipValueGetter: (params) => returnCodes[params.value],
      filter: DropdownFilter,
      filterParams: {
        returnCodes: returnCodes,
        selectorPlaceholderLocales: JSON.parse(selectorPlaceholderLocales),
      },
      sortable: true,
    },
    {
      field: "actions",
      ...DEFAULT_COL_DEF,
      ...ACTIONS_COL_DEF,
      onCellClicked(params) {
        rowActions.onActionCellClicked(params, function (action) {
          switch (action) {
            case rowActions.Action.CANCEL:
              return rowActions.handleCancel(params);
            case rowActions.Action.DISCARD:
              return handleDiscard(params);
            case rowActions.Action.EDIT:
              return handleEdit(params, defaultPaymentMethod);
            case rowActions.Action.PROVISION:
              return handleProvision(params);
          }
        });
      },
    },
  ];

  const dataSource = new datasource.Datasource({ callbacks: dataCallbacks, indexPath, limit: paginationPageSize });

  const options: AgGrid.GridOptions<MadePayment> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: {
      ...(gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef as AgGrid.ColDef<MadePayment, any>),
      suppressKeyboardEvent(params) {
        return rowActions.suppressKeyboardEvent(params, {
          handleEdit(params) {
            handleEdit(params, defaultPaymentMethod);
          },
          handleProvision,
        });
      },
    },
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    localeText: {
      ...FILTER_TRANSLATIONS,
    },
    getRowId: (params) => params.data.id,
    // editing
    editType: "fullRow",
    rowModelType: "clientSide",
    suppressClickEdit: true,
    // events
    onCellEditingStopped(params) {
      rowActions.hide(gridUtils.actionsCell(params));
      params.node.setDataValue(params.column, params.oldValue);
    },
    onCellMouseOver: rowActions.GRID_CALLBACKS.onCellMouseOver,
    onCellMouseOut: rowActions.GRID_CALLBACKS.onCellMouseOut,
    onColumnResized(event) {
      columnsState.onResize(event);
    },
    onRowDataUpdated: ctrlCallbacks.subscribeOnRowDataUpdated,
    onRowDoubleClicked(params) {
      if (!gridUtils.isEditing(params) && gridUtils.isEditable(params)) {
        handleEdit(params, defaultPaymentMethod);
      }
    },
    onRowEditingStopped(event) {
      rowActions.hide(gridUtils.actionsCell(event));
    },
  };
  const gridApi = AgGrid.createGrid($grid, options);

  dataSource.getRows({
    context: null,
    endRow: 1,
    failCallback: () => {},
    filterModel: gridApi.getFilterModel(),
    sortModel: gridApi.getState().sort.sortModel,
    startRow: 1,
    successCallback: (records) => {
      gridApi.setGridOption("loading", false);
      gridApi.setGridOption("rowData", records);
    },
  });

  const columnsState = new ColumnsState(gridApi, "next_allocations_due_payments");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });
  return gridApi;
}

export default class extends Controller {
  REVIEW_BUTTON_I18N_KEY = "next_allocations.made_batches.payments.actions.create_review";

  static targets = ["grid", "totalPaymentAmount"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;
  declare readonly hasTotalPaymentAmountTarget: boolean;
  declare readonly totalPaymentAmountTarget: HTMLInputElement;

  gridApi: AgGrid.GridApi;

  connect() {
    const ctrl = this;

    if (this.hasGridTarget) {
      const $resetGridBtn: HTMLElement = this.element.querySelector(".reset-grid");
      const $createReviewBtn: HTMLElement = document.querySelector(".create-review");

      this.gridApi = buildGrid(
        this.gridTarget,
        {
          onDataReceivedSuccess(response: { records: MadePayment[] }) {
            ctrl.displayReviewButton(response.records, $createReviewBtn);
          },
        },
        {
          subscribeOnRowDataUpdated(event) {
            ctrl.displayReviewButton(gridUtils.nodesData(event.api), $createReviewBtn);
          },
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }

  async displayReviewButton(payments: MadePayment[], createReviewBtn?: HTMLElement) {
    if (createReviewBtn) {
      const count = payments.filter((x) => x.status == MadePaymentStatus.PROVISIONAL_RETURN).length;

      if (count > 0) {
        createReviewBtn.innerText = await translations.t(this.REVIEW_BUTTON_I18N_KEY, { count });
        dom.showElement(createReviewBtn);
      } else {
        dom.hideElement(createReviewBtn);
      }
    }
  }

  addPayment({ detail: { content: payment } }) {
    this.gridApi.applyTransaction({ add: [payment] });
  }
}

import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as columns from "grid/columns";
import * as datasource from "grid/datasource";
import * as dom from "utils/dom";
import * as gridUtils from "../../grid/utils";
import * as http from "utils/http";
import * as notifications from "utils/notifications";
import * as rowActions from "./row_actions";
import { ColumnsState } from "grid/columns_state";

const httpClient = http.buildClient(rowActions.cutoffTimeResponseInterceptor);

import {
  ACTIONS_COL_DEF,
  AMOUNT_COL_DEF,
  AMOUNT_FILTER_COL_DEF,
  DATE_FILTER_COL_DEF,
  DATE_FILTER_UNTIL_NOW_COL_DEF,
  DEFAULT_COL_DEF,
  FILTER_TRANSLATIONS,
  TEXT_FILTER_COL_DEF,
} from "../../grid/columns";

import {
  EDITABLE_ACCOUNT_NUMBER_COL_DEF,
  EDITABLE_AMOUNT_COL_DEF,
  EDITABLE_DATE_COL_DEF,
  EDITABLE_TEXT_COL_DEF,
} from "./columns";

async function handleDiscard(params: rowActions.ActionEvent<DuePayment>) {
  const response = await httpClient.httpDelete(params.data.discard_path);

  if (response.ok) {
    params.api.applyTransaction({ remove: [params.data] });
  } else {
    const data = await response.json();
    notifications.alert(data.error);
  }
}

function handleEdit(params: rowActions.ActionEvent<DuePayment>, defaultPaymentMethod: string) {
  params.data.payment_amount ||= params.data.due_amount;
  params.data.payment_date = columns.formatDateToUSFormat(params.data.payment_date);
  params.data.payment_method ||= defaultPaymentMethod;
  params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: "payment_method" });
  rowActions.show(gridUtils.actionsCell(params), true);
}

async function handleProvision(params: rowActions.ActionEvent<DuePayment>) {
  params.api.stopEditing();

  var response: Response;
  const isUpdate = Boolean(params.data.id);
  params.api.setGridOption("pinnedTopRowData", []);
  params.api.stopEditing();
  if (isUpdate) {
    const body = {
      due_payment: {
        comment: params.data.comment,
        payment_amount: params.data.payment_amount,
        payment_date: params.data.payment_date,
        payment_method: params.data.payment_method,
      },
    };
    response = await httpClient.httpPatch(params.data.update_path, body);
  } else {
    const body = {
      due_payment: {
        account_number: params.data.account_number,
        comment: params.data.comment,
        customer_name: params.data.customer_name,
        due_amount: params.data.due_amount,
        due_date: params.data.due_date,
        id: params.data.id,
        payment_amount: params.data.payment_amount,
        payment_date: params.data.payment_date,
        payment_method: params.data.payment_method,
      },
    };
    response = await httpClient.httpPost(params.data.provision_path, body);
  }
  const data = await response.json();

  if (response.ok) {
    if (isUpdate) {
      params.api.applyTransaction({ update: [data] });
    } else {
      params.api.applyTransaction({ add: [data] });
    }
  } else {
    notifications.alert(data.error);
  }
}

async function addAllocation(api: AgGrid.GridApi, url: string) {
  const response = await httpClient.httpGet(url);
  const data = await response.json();

  if (response.ok) {
    if (data.payment_date) {
      data.payment_date = columns.formatDateToUSFormat(data.payment_date);
    }

    api.setGridOption("pinnedTopRowData", [data]);
    api.startEditingCell({ rowIndex: 0, rowPinned: "top", colKey: "account_number" });
    rowActions.show(document.querySelector('.ag-row-pinned div[col-id="actions"]'), true);
  } else {
    notifications.alert(data.error);
  }
}

interface DuePayment {
  account_number: string;
  comment: string;
  currency_code: string;
  customer_name: string;
  discard_path: string;
  due_amount: number;
  due_date: string;
  editable: boolean;
  id: string;
  payment_amount: number;
  payment_date: string;
  payment_method: string;
  provision_path: string;
  update_path: string;
}

interface CtrlCallbacks {
  subscribeOnAddAllocation(callback: () => void): void;
  subscribeOnResetColumnWidths(callback: () => void): void;
  subscribeOnRowDataUpdated(event: AgGrid.RowDataUpdatedEvent<DuePayment, any>): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { defaultPaymentMethod, i18nJson, indexPath, newAllocationUrl, paginationPageSize, paymentMethodsJson } =
    $grid.dataset;

  const colDefs: AgGrid.ColDef[] = [
    { field: "account_number", ...EDITABLE_ACCOUNT_NUMBER_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "due_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    { field: "due_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true, sort: "desc" },
    {
      field: "customer_name",
      ...DEFAULT_COL_DEF,
      ...TEXT_FILTER_COL_DEF,
      tooltipField: "customer_name",
      sortable: true,
    },
    { field: "payment_amount", ...EDITABLE_AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "payment_method",
      ...DEFAULT_COL_DEF,
      sortable: true,
      ...columns.buildEnumEditableColDef(JSON.parse(paymentMethodsJson)),
      ...columns.buildEnumFilterColDef(JSON.parse(paymentMethodsJson)),
    },
    {
      field: "payment_date",
      ...EDITABLE_DATE_COL_DEF,
      ...DATE_FILTER_UNTIL_NOW_COL_DEF,
      sortable: true,
      cellEditorParams: {
        ...EDITABLE_DATE_COL_DEF.cellEditorParams,
      },
    },
    { field: "comment", ...EDITABLE_TEXT_COL_DEF, tooltipField: "comment", sortable: false },
    {
      field: "actions",
      ...ACTIONS_COL_DEF,
      onCellClicked(params) {
        rowActions.onActionCellClicked(params, function (action) {
          switch (action) {
            case rowActions.Action.CANCEL:
              return rowActions.handleCancel(params);
            case rowActions.Action.DISCARD:
              return handleDiscard(params);
            case rowActions.Action.EDIT:
              return handleEdit(params, defaultPaymentMethod);
            case rowActions.Action.PROVISION:
              return handleProvision(params);
          }
        });
      },
    },
  ];

  const dataSource = new datasource.Datasource({ callbacks: dataCallbacks, indexPath, limit: paginationPageSize });

  const options: AgGrid.GridOptions<DuePayment> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: {
      ...(gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef as AgGrid.ColDef<DuePayment, any>),
      suppressKeyboardEvent(params) {
        return rowActions.suppressKeyboardEvent(params, {
          handleEdit(params) {
            handleEdit(params, defaultPaymentMethod);
          },
          handleProvision,
        });
      },
    },
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    localeText: {
      ...FILTER_TRANSLATIONS,
    },
    getRowId: (params) => params.data.id,
    // editing
    editType: "fullRow",
    rowModelType: "clientSide",
    suppressClickEdit: true,
    suppressNoRowsOverlay: true,
    // events
    onCellEditingStopped(params) {
      if (gridUtils.isRowPinned(params)) {
        gridUtils.restartEditingPinnedRow(params, gridApi);
      } else {
        rowActions.hide(gridUtils.actionsCell(params));
        params.node.setDataValue(params.column, params.oldValue);
      }
    },
    onCellMouseOver: rowActions.GRID_CALLBACKS.onCellMouseOver,
    onCellMouseOut: rowActions.GRID_CALLBACKS.onCellMouseOut,
    onColumnResized(event) {
      columnsState.onResize(event);
    },
    onRowDataUpdated: ctrlCallbacks.subscribeOnRowDataUpdated,
    onRowDoubleClicked(params) {
      if (!gridUtils.isEditing(params) && gridUtils.isEditable(params)) {
        handleEdit(params, defaultPaymentMethod);
      }
    },
    onRowEditingStopped(params) {
      if (gridUtils.isRowPinned(params)) {
        gridUtils.restartEditingPinnedRow(params, gridApi);
      } else {
        rowActions.hide(gridUtils.actionsCell(params));
      }
    },
    context: {
      i18n: JSON.parse(i18nJson),
    },
  };
  const gridApi = AgGrid.createGrid($grid, options);

  dataSource.getRows({
    context: null,
    endRow: 1,
    failCallback: () => {},
    filterModel: gridApi.getFilterModel(),
    sortModel: gridApi.getState().sort.sortModel,
    startRow: 1,
    successCallback: (records) => {
      gridApi.setGridOption("loading", false);
      gridApi.setGridOption("rowData", records);
    },
  });

  const columnsState = new ColumnsState(gridApi, "next_allocations_due_payments");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnAddAllocation(() => addAllocation(gridApi, newAllocationUrl));
  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });
  return gridApi;
}

export default class extends Controller {
  static targets = ["grid", "totalPaymentAmount"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;
  declare readonly hasTotalPaymentAmountTarget: boolean;
  declare readonly totalPaymentAmountTarget: HTMLInputElement;

  gridApi: AgGrid.GridApi;

  connect() {
    const ctrl = this;

    if (this.hasGridTarget) {
      const $addAllocationBtn: HTMLElement = document.querySelector(".add-allocation");
      const $resetGridBtn: HTMLElement = this.element.querySelector(".reset-grid");
      const $createReviewBtn: HTMLElement = document.querySelector(".create-review");

      this.gridApi = buildGrid(
        this.gridTarget,
        {
          onDataReceivedSuccess(response: any) {
            ctrl.displayReviewButton(response.records, $createReviewBtn);
          },
        },
        {
          subscribeOnRowDataUpdated(event) {
            const nodesData = gridUtils.nodesData(event.api);
            ctrl.displayTotals(nodesData);
            ctrl.displayReviewButton(nodesData, $createReviewBtn);
          },
          subscribeOnAddAllocation(callback) {
            if ($addAllocationBtn) {
              $addAllocationBtn.onclick = callback;
            }
          },
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }

  displayReviewButton(payments: DuePayment[], createReviewBtn?: HTMLElement) {
    if (createReviewBtn) {
      (payments.length > 0 ? dom.showElement : dom.hideElement)(createReviewBtn);
    }
  }

  displayTotals(payments: DuePayment[]) {
    if (this.hasTotalPaymentAmountTarget) {
      const totalAmount = payments.reduce((sum, payment) => sum + payment.payment_amount, 0);
      if (totalAmount > 0) {
        this.totalPaymentAmountTarget.innerText = columns.formatAmount(totalAmount, payments[0].currency_code);
      }
      (totalAmount > 0 ? dom.showElement : dom.hideElement)(this.totalPaymentAmountTarget.closest("p"));
    }
  }

  addPayment({ detail: { content: payment } }) {
    this.gridApi.applyTransaction({ add: [payment] });
  }
}
